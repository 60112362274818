/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type useStaticFlattenReplies = {
    readonly flattenReplies: boolean;
    readonly " $refType": "useStaticFlattenReplies";
};
export type useStaticFlattenReplies$data = useStaticFlattenReplies;
export type useStaticFlattenReplies$key = {
    readonly " $data"?: useStaticFlattenReplies$data;
    readonly " $fragmentRefs": FragmentRefs<"useStaticFlattenReplies">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useStaticFlattenReplies",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flattenReplies",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any);
(node as any).hash = '7b0d0bd2dabff2b597ae645b262d30db';
export default node;
