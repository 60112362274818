/* tslint:disable */
/* eslint-disable */
/* @relayHash 60e64631f218494e07fb5015afc1b903 */

import { ConcreteRequest } from "relay-runtime";
export type UpdateUserUsernameInput = {
    userID: string;
    username: string;
    initialized?: boolean | null;
    clientMutationId: string;
};
export type UpdateUserUsernameMutationVariables = {
    input: UpdateUserUsernameInput;
};
export type UpdateUserUsernameMutationResponse = {
    readonly updateUserUsername: {
        readonly user: {
            readonly id: string;
            readonly username: string | null;
        };
        readonly clientMutationId: string;
    };
};
export type UpdateUserUsernameMutation = {
    readonly response: UpdateUserUsernameMutationResponse;
    readonly variables: UpdateUserUsernameMutationVariables;
};



/*
mutation UpdateUserUsernameMutation(
  $input: UpdateUserUsernameInput!
) {
  updateUserUsername(input: $input) {
    user {
      id
      username
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "UpdateUserUsernamePayload",
            "kind": "LinkedField",
            "name": "updateUserUsername",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateUserUsernameMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateUserUsernameMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "60e64631f218494e07fb5015afc1b903",
            "metadata": {},
            "name": "UpdateUserUsernameMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '7310ceb95b3fa1cf4a545506838d7ed3';
export default node;
