/* tslint:disable */
/* eslint-disable */
/* @relayHash 5340c13cc25b41236032da2731fef03d */

import { ConcreteRequest } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "READER" | "STAFF" | "%future added value";
export type UpdateUserMembershipScopesInput = {
    userID: string;
    membershipScopes: MembershipScopesInput;
    clientMutationId: string;
};
export type MembershipScopesInput = {
    scoped: boolean;
    siteIDs: Array<string>;
};
export type UpdateUserMembershipScopesMutationVariables = {
    input: UpdateUserMembershipScopesInput;
};
export type UpdateUserMembershipScopesMutationResponse = {
    readonly updateUserMembershipScopes: {
        readonly user: {
            readonly id: string;
            readonly role: USER_ROLE;
            readonly membershipScopes: {
                readonly scoped: boolean;
                readonly sites: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                }> | null;
            } | null;
        };
    };
};
export type UpdateUserMembershipScopesMutation = {
    readonly response: UpdateUserMembershipScopesMutationResponse;
    readonly variables: UpdateUserMembershipScopesMutationVariables;
};



/*
mutation UpdateUserMembershipScopesMutation(
  $input: UpdateUserMembershipScopesInput!
) {
  updateUserMembershipScopes(input: $input) {
    user {
      id
      role
      membershipScopes {
        scoped
        sites {
          id
          name
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v2 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "UpdateUserMembershipScopesPayload",
            "kind": "LinkedField",
            "name": "updateUserMembershipScopes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserMembershipScopes",
                            "kind": "LinkedField",
                            "name": "membershipScopes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "scoped",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateUserMembershipScopesMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateUserMembershipScopesMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "id": "5340c13cc25b41236032da2731fef03d",
            "metadata": {},
            "name": "UpdateUserMembershipScopesMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '4fa82d9ac2dd19b7df1c0b407bd87f9f';
export default node;
