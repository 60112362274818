/* tslint:disable */
/* eslint-disable */
/* @relayHash 375d14070edf63e2eb50f347a6682e43 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_FLAG_REASON = "COMMENT_DETECTED_BANNED_WORD" | "COMMENT_DETECTED_LINKS" | "COMMENT_DETECTED_NEW_COMMENTER" | "COMMENT_DETECTED_PREMOD_USER" | "COMMENT_DETECTED_RECENT_HISTORY" | "COMMENT_DETECTED_REPEAT_POST" | "COMMENT_DETECTED_SPAM" | "COMMENT_DETECTED_SUSPECT_WORD" | "COMMENT_DETECTED_TOXIC" | "COMMENT_REPORTED_ABUSIVE" | "COMMENT_REPORTED_BIO" | "COMMENT_REPORTED_OFFENSIVE" | "COMMENT_REPORTED_OTHER" | "COMMENT_REPORTED_SPAM" | "%future added value";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type TAG = "ADMIN" | "AUTO_HIDDEN" | "EXPERT" | "FEATURED" | "HIDDEN" | "MEMBER" | "MODERATOR" | "QUESTION" | "REVIEW" | "STAFF" | "UNANSWERED" | "%future added value";
export type SingleModerateSubscriptionVariables = {
    commentID: string;
};
export type SingleModerateSubscriptionResponse = {
    readonly commentStatusUpdated: {
        readonly comment: {
            readonly id: string;
            readonly status: COMMENT_STATUS;
            readonly tags: ReadonlyArray<{
                readonly code: TAG;
            }>;
            readonly revision: {
                readonly actionCounts: {
                    readonly flag: {
                        readonly reasons: {
                            readonly COMMENT_REPORTED_SPAM: number;
                            readonly COMMENT_REPORTED_OTHER: number;
                            readonly COMMENT_REPORTED_OFFENSIVE: number;
                            readonly COMMENT_REPORTED_ABUSIVE: number;
                            readonly COMMENT_REPORTED_BIO: number;
                            readonly COMMENT_DETECTED_TOXIC: number;
                            readonly COMMENT_DETECTED_SUSPECT_WORD: number;
                            readonly COMMENT_DETECTED_SPAM: number;
                            readonly COMMENT_DETECTED_REPEAT_POST: number;
                            readonly COMMENT_DETECTED_RECENT_HISTORY: number;
                            readonly COMMENT_DETECTED_NEW_COMMENTER: number;
                            readonly COMMENT_DETECTED_LINKS: number;
                            readonly COMMENT_DETECTED_BANNED_WORD: number;
                        };
                    };
                };
                readonly metadata: {
                    readonly wordList: {
                        readonly timedOut: boolean | null;
                    } | null;
                    readonly externalModeration: ReadonlyArray<{
                        readonly name: string;
                        readonly analyzedAt: string;
                        readonly result: {
                            readonly status: COMMENT_STATUS | null;
                            readonly tags: ReadonlyArray<TAG> | null;
                            readonly actions: ReadonlyArray<{
                                readonly reason: COMMENT_FLAG_REASON | null;
                            }> | null;
                        };
                    }> | null;
                };
            } | null;
            readonly viewerDidModerate: boolean | null;
            readonly statusHistory: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly status: COMMENT_STATUS;
                        readonly moderator: {
                            readonly id: string;
                            readonly username: string | null;
                        } | null;
                    };
                }>;
            };
            readonly " $fragmentRefs": FragmentRefs<"ModerateCardDetailsContainer_comment">;
        };
    };
};
export type SingleModerateSubscription = {
    readonly response: SingleModerateSubscriptionResponse;
    readonly variables: SingleModerateSubscriptionVariables;
};



/*
subscription SingleModerateSubscription(
  $commentID: ID!
) {
  commentStatusUpdated(id: $commentID) {
    comment {
      id
      status
      ...ModerateCardDetailsContainer_comment
      tags {
        code
      }
      revision {
        actionCounts {
          flag {
            reasons {
              COMMENT_REPORTED_SPAM
              COMMENT_REPORTED_OTHER
              COMMENT_REPORTED_OFFENSIVE
              COMMENT_REPORTED_ABUSIVE
              COMMENT_REPORTED_BIO
              COMMENT_DETECTED_TOXIC
              COMMENT_DETECTED_SUSPECT_WORD
              COMMENT_DETECTED_SPAM
              COMMENT_DETECTED_REPEAT_POST
              COMMENT_DETECTED_RECENT_HISTORY
              COMMENT_DETECTED_NEW_COMMENTER
              COMMENT_DETECTED_LINKS
              COMMENT_DETECTED_BANNED_WORD
            }
          }
        }
        metadata {
          wordList {
            timedOut
          }
          externalModeration {
            name
            analyzedAt
            result {
              status
              tags
              actions {
                reason
              }
            }
          }
        }
        id
      }
      statusHistory(first: 1) {
        edges {
          node {
            status
            moderator {
              id
              username
            }
            id
          }
        }
      }
    }
  }
}

fragment AutomatedActionsContainer_comment on Comment {
  revision {
    metadata {
      perspective {
        score
      }
      externalModeration {
        name
        analyzedAt
        result {
          status
          tags
          actions {
            reason
          }
        }
      }
    }
    id
  }
}

fragment CommentRevisionContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    id
  }
  revisionHistory {
    id
    body
    createdAt
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
  }
}

fragment FlagDetailsContainer_comment on Comment {
  flags {
    nodes {
      flagger {
        username
        id
      }
      reason
      additionalDetails
      id
    }
  }
  revision {
    metadata {
      perspective {
        score
      }
    }
    id
  }
}

fragment LinkDetailsContainer_comment on Comment {
  id
  story {
    id
    url
  }
}

fragment ModerateCardDetailsContainer_comment on Comment {
  id
  status
  tags {
    code
  }
  editing {
    edited
  }
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_ABUSIVE
          COMMENT_REPORTED_SPAM
          COMMENT_REPORTED_OTHER
          COMMENT_DETECTED_TOXIC
          COMMENT_DETECTED_SPAM
          COMMENT_REPORTED_BIO
        }
      }
      reaction {
        total
      }
    }
    metadata {
      perspective {
        score
      }
      externalModeration {
        name
        result {
          status
          tags
          actions {
            reason
          }
        }
      }
    }
    id
  }
  ...FlagDetailsContainer_comment
  ...CommentRevisionContainer_comment
  ...LinkDetailsContainer_comment
  ...AutomatedActionsContainer_comment
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "commentID"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "commentID"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tags",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_SPAM",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_OTHER",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_OFFENSIVE",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_ABUSIVE",
        "storageKey": null
    } as any), v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_BIO",
        "storageKey": null
    } as any), v10 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_TOXIC",
        "storageKey": null
    } as any), v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_SUSPECT_WORD",
        "storageKey": null
    } as any), v12 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_SPAM",
        "storageKey": null
    } as any), v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_REPEAT_POST",
        "storageKey": null
    } as any), v14 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_RECENT_HISTORY",
        "storageKey": null
    } as any), v15 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_NEW_COMMENTER",
        "storageKey": null
    } as any), v16 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_LINKS",
        "storageKey": null
    } as any), v17 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_BANNED_WORD",
        "storageKey": null
    } as any), v18 = ({
        "alias": null,
        "args": null,
        "concreteType": "CommentRevisionWordListMetadata",
        "kind": "LinkedField",
        "name": "wordList",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timedOut",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v19 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v20 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "analyzedAt",
        "storageKey": null
    } as any), v21 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reason",
        "storageKey": null
    } as any), v22 = ({
        "alias": null,
        "args": null,
        "concreteType": "ExternalModerationPhaseResult",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tags",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ExternalModerationPhaseAction",
                "kind": "LinkedField",
                "name": "actions",
                "plural": true,
                "selections": [
                    (v21 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v23 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 1
        } as any)
    ], v24 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v25 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "moderator",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            (v24 /*: any*/)
        ],
        "storageKey": null
    } as any), v26 = ({
        "kind": "ClientExtension",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerDidModerate",
                "storageKey": null
            }
        ]
    } as any), v27 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v28 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v29 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v30 = [
        (v27 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SingleModerateSubscription",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CommentStatusUpdatedPayload",
                    "kind": "LinkedField",
                    "name": "commentStatusUpdated",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "comment",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "kind": "LinkedField",
                                    "name": "revision",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ActionCounts",
                                            "kind": "LinkedField",
                                            "name": "actionCounts",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "FlagActionCounts",
                                                    "kind": "LinkedField",
                                                    "name": "flag",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "FlagReasonActionCounts",
                                                            "kind": "LinkedField",
                                                            "name": "reasons",
                                                            "plural": false,
                                                            "selections": [
                                                                (v5 /*: any*/),
                                                                (v6 /*: any*/),
                                                                (v7 /*: any*/),
                                                                (v8 /*: any*/),
                                                                (v9 /*: any*/),
                                                                (v10 /*: any*/),
                                                                (v11 /*: any*/),
                                                                (v12 /*: any*/),
                                                                (v13 /*: any*/),
                                                                (v14 /*: any*/),
                                                                (v15 /*: any*/),
                                                                (v16 /*: any*/),
                                                                (v17 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentRevisionMetadata",
                                            "kind": "LinkedField",
                                            "name": "metadata",
                                            "plural": false,
                                            "selections": [
                                                (v18 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevisionExternalModerationPhaseMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "externalModeration",
                                                    "plural": true,
                                                    "selections": [
                                                        (v19 /*: any*/),
                                                        (v20 /*: any*/),
                                                        (v22 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v23 /*: any*/),
                                    "concreteType": "CommentModerationActionConnection",
                                    "kind": "LinkedField",
                                    "name": "statusHistory",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentModerationActionEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentModerationAction",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v25 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "statusHistory(first:1)"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ModerateCardDetailsContainer_comment"
                                },
                                (v26 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Subscription",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SingleModerateSubscription",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CommentStatusUpdatedPayload",
                    "kind": "LinkedField",
                    "name": "commentStatusUpdated",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "comment",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EditInfo",
                                    "kind": "LinkedField",
                                    "name": "editing",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "edited",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "kind": "LinkedField",
                                    "name": "revision",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ActionCounts",
                                            "kind": "LinkedField",
                                            "name": "actionCounts",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "FlagActionCounts",
                                                    "kind": "LinkedField",
                                                    "name": "flag",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "FlagReasonActionCounts",
                                                            "kind": "LinkedField",
                                                            "name": "reasons",
                                                            "plural": false,
                                                            "selections": [
                                                                (v7 /*: any*/),
                                                                (v8 /*: any*/),
                                                                (v5 /*: any*/),
                                                                (v6 /*: any*/),
                                                                (v10 /*: any*/),
                                                                (v12 /*: any*/),
                                                                (v9 /*: any*/),
                                                                (v11 /*: any*/),
                                                                (v13 /*: any*/),
                                                                (v14 /*: any*/),
                                                                (v15 /*: any*/),
                                                                (v16 /*: any*/),
                                                                (v17 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ReactionActionCounts",
                                                    "kind": "LinkedField",
                                                    "name": "reaction",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "total",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentRevisionMetadata",
                                            "kind": "LinkedField",
                                            "name": "metadata",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevisionPerspectiveMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "perspective",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "score",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevisionExternalModerationPhaseMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "externalModeration",
                                                    "plural": true,
                                                    "selections": [
                                                        (v19 /*: any*/),
                                                        (v22 /*: any*/),
                                                        (v20 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v18 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FlagsConnection",
                                    "kind": "LinkedField",
                                    "name": "flags",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Flag",
                                            "kind": "LinkedField",
                                            "name": "nodes",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "flagger",
                                                    "plural": false,
                                                    "selections": [
                                                        (v24 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v21 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "additionalDetails",
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "site",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "kind": "LinkedField",
                                    "name": "revisionHistory",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "body",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "media",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v27 /*: any*/),
                                                        (v28 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "width",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "height",
                                                            "storageKey": null
                                                        },
                                                        (v29 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "video",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "GiphyMedia",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": (v30 /*: any*/),
                                                    "type": "TwitterMedia",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v27 /*: any*/),
                                                        (v29 /*: any*/),
                                                        (v28 /*: any*/)
                                                    ],
                                                    "type": "YouTubeMedia",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": (v30 /*: any*/),
                                                    "type": "ExternalMedia",
                                                    "abstractKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Story",
                                    "kind": "LinkedField",
                                    "name": "story",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v27 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v23 /*: any*/),
                                    "concreteType": "CommentModerationActionConnection",
                                    "kind": "LinkedField",
                                    "name": "statusHistory",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentModerationActionEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentModerationAction",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v25 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "statusHistory(first:1)"
                                },
                                (v26 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "375d14070edf63e2eb50f347a6682e43",
            "metadata": {},
            "name": "SingleModerateSubscription",
            "operationKind": "subscription",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'e5e984549b07269fe0d8686b74c071df';
export default node;
