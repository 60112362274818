/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MODERATION_MODE = "POST" | "PRE" | "SPECIFIC_SITES_PRE" | "%future added value";
export type ModerationConfigContainer_settings = {
    readonly integrations: {
        readonly akismet: {
            readonly enabled: boolean;
            readonly ipBased: boolean;
            readonly key: string | null;
            readonly site: string | null;
        };
        readonly perspective: {
            readonly enabled: boolean;
            readonly endpoint: string | null;
            readonly key: string | null;
            readonly model: string | null;
            readonly threshold: number | null;
            readonly doNotStore: boolean | null;
            readonly sendFeedback: boolean | null;
        };
    };
    readonly moderation: MODERATION_MODE | null;
    readonly premodLinksEnable: boolean | null;
    readonly premoderateSuspectWords: boolean;
    readonly premoderateAllCommentsSites: ReadonlyArray<string>;
    readonly recentCommentHistory: {
        readonly enabled: boolean;
        readonly timeFrame: number;
        readonly triggerRejectionRate: number;
    };
    readonly newCommenters: {
        readonly premodEnabled: boolean;
        readonly approvedCommentsThreshold: number;
        readonly moderation: {
            readonly mode: MODERATION_MODE | null;
            readonly premodSites: ReadonlyArray<string>;
        } | null;
    };
    readonly externalProfileURL: string | null;
    readonly commentsNumberLimit: {
        readonly weeklyLimit: number | null;
        readonly weeklyWarningThreshold: number | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"PreModerationConfigContainer_settings" | "NewCommentersConfigContainer_settings" | "EmailDomainConfigContainer_settings" | "ExternalLinksConfigContainer_settings">;
    readonly " $refType": "ModerationConfigContainer_settings";
};
export type ModerationConfigContainer_settings$data = ModerationConfigContainer_settings;
export type ModerationConfigContainer_settings$key = {
    readonly " $data"?: ModerationConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerationConfigContainer_settings">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ModerationConfigContainer_settings",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ExternalIntegrations",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AkismetExternalIntegration",
                        "kind": "LinkedField",
                        "name": "akismet",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ipBased",
                                "storageKey": null
                            },
                            (v1 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "site",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PerspectiveExternalIntegration",
                        "kind": "LinkedField",
                        "name": "perspective",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endpoint",
                                "storageKey": null
                            },
                            (v1 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "model",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "threshold",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "doNotStore",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sendFeedback",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "moderation",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "premodLinksEnable",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "premoderateSuspectWords",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "premoderateAllCommentsSites",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "RecentCommentHistoryConfiguration",
                "kind": "LinkedField",
                "name": "recentCommentHistory",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeFrame",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "triggerRejectionRate",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "NewCommentersConfiguration",
                "kind": "LinkedField",
                "name": "newCommenters",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "premodEnabled",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "approvedCommentsThreshold",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "NewCommentersModerationConfig",
                        "kind": "LinkedField",
                        "name": "moderation",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mode",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "premodSites",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalProfileURL",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentsNumberLimitConfiguration",
                "kind": "LinkedField",
                "name": "commentsNumberLimit",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "weeklyLimit",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "weeklyWarningThreshold",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PreModerationConfigContainer_settings"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NewCommentersConfigContainer_settings"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EmailDomainConfigContainer_settings"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ExternalLinksConfigContainer_settings"
            }
        ],
        "type": "Settings",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '960807d38cf08169f43d3317121736e8';
export default node;
