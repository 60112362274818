/* tslint:disable */
/* eslint-disable */
/* @relayHash 307d3851674ec62d09fb6ad2ccc07428 */

import { ConcreteRequest } from "relay-runtime";
export type ToggleMemberBioInput = {
    clientMutationId: string;
    userID: string;
    enabled: boolean;
};
export type ToggleMemberBioMutationVariables = {
    input: ToggleMemberBioInput;
};
export type ToggleMemberBioMutationResponse = {
    readonly toggleMemberBio: {
        readonly user: {
            readonly id: string;
            readonly bio: string | null;
            readonly status: {
                readonly bio: {
                    readonly enabled: boolean;
                };
            };
        };
        readonly clientMutationId: string;
    };
};
export type ToggleMemberBioMutation = {
    readonly response: ToggleMemberBioMutationResponse;
    readonly variables: ToggleMemberBioMutationVariables;
};



/*
mutation ToggleMemberBioMutation(
  $input: ToggleMemberBioInput!
) {
  toggleMemberBio(input: $input) {
    user {
      id
      bio
      status {
        bio {
          enabled
        }
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ToggleMemberBioPayload",
            "kind": "LinkedField",
            "name": "toggleMemberBio",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bio",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BioStatus",
                                    "kind": "LinkedField",
                                    "name": "bio",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "enabled",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ToggleMemberBioMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ToggleMemberBioMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "307d3851674ec62d09fb6ad2ccc07428",
            "metadata": {},
            "name": "ToggleMemberBioMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '6642038bd5bf926b2675873e76e58641';
export default node;
