/* tslint:disable */
/* eslint-disable */
/* @relayHash 59771d40211e6687839594c93f6b6fc2 */

import { ConcreteRequest } from "relay-runtime";
export type SaveMemberBioInput = {
    clientMutationId: string;
    userID: string;
    bio?: string | null;
};
export type SaveMemberBioMutationVariables = {
    input: SaveMemberBioInput;
};
export type SaveMemberBioMutationResponse = {
    readonly saveMemberBio: {
        readonly user: {
            readonly id: string;
            readonly bio: string | null;
        };
        readonly clientMutationId: string;
    };
};
export type SaveMemberBioMutation = {
    readonly response: SaveMemberBioMutationResponse;
    readonly variables: SaveMemberBioMutationVariables;
};



/*
mutation SaveMemberBioMutation(
  $input: SaveMemberBioInput!
) {
  saveMemberBio(input: $input) {
    user {
      id
      bio
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "SaveMemberBioPayload",
            "kind": "LinkedField",
            "name": "saveMemberBio",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bio",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SaveMemberBioMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SaveMemberBioMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "59771d40211e6687839594c93f6b6fc2",
            "metadata": {},
            "name": "SaveMemberBioMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '5acc8d9ae3c8283e6ca41c057d1dd666';
export default node;
