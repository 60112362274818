import React, { FunctionComponent } from "react";
import { graphql } from "react-relay";

import TargetFilterField from "coral-admin/routes/Configure/sections/Auth/TargetFilterField";
import Header from "../../Header";
import ConfigBoxWithToggleField from "./ConfigBoxWithToggleField";

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment RempAuthConfigContainer_formValues on Auth {
    integrations {
      remp {
        enabled
        targetFilter {
          admin
          stream
        }
      }
    }
  }
`;

interface Props {
  disabled?: boolean;
}

const RempAuthConfigContainer: FunctionComponent<Props> = ({ disabled }) => {
  return (
    <ConfigBoxWithToggleField
      title={<Header container="h2">Login with REMP authentication</Header>}
      name="auth.integrations.remp.enabled"
      disabled={disabled}
      data-testid="configure-auth-remp"
    >
      {(disabledInside) => (
        <>
          <TargetFilterField
            label={<span>Allow integration with REMP using n_token for</span>}
            name="auth.integrations.remp.targetFilter"
            disabled={disabledInside}
          />
        </>
      )}
    </ConfigBoxWithToggleField>
  );
};

export default RempAuthConfigContainer;
