import { graphql } from "react-relay";
import { Environment } from "relay-runtime";

import {
  commitMutationPromiseNormalized,
  createMutation,
  MutationInput,
} from "coral-framework/lib/relay";

import { ToggleMemberBioMutation as MutationTypes } from "coral-admin/__generated__/ToggleMemberBioMutation.graphql";

let clientMutationId = 0;

const ToggleMemberBioMutation = createMutation(
  "toggleMemberBio",
  (environment: Environment, input: MutationInput<MutationTypes>) => {
    return commitMutationPromiseNormalized<MutationTypes>(environment, {
      mutation: graphql`
        mutation ToggleMemberBioMutation($input: ToggleMemberBioInput!) {
          toggleMemberBio(input: $input) {
            user {
              id
              bio
              status {
                bio {
                  enabled
                }
              }
            }
            clientMutationId
          }
        }
      `,
      variables: {
        input: {
          ...input,
          clientMutationId: clientMutationId.toString(),
        },
      },
      // optimisticResponse: {
      //   toggleMemberBio: {
      //     user: {
      //       id: input.userID,
      //       bio: input.bio ?? null,
      //     },
      //     clientMutationId: (clientMutationId++).toString(),
      //   },
      // },
    });
  }
);

export default ToggleMemberBioMutation;
