import React, { FunctionComponent, useCallback, useState } from "react";
import { graphql } from "react-relay";

import { useMutation, withFragmentContainer } from "coral-framework/lib/relay";

import cn from "classnames";
import { MemberUsernameContainer_user } from "coral-admin/__generated__/MemberUsernameContainer_user.graphql";
import UpdateUserUsernameMutation from "coral-admin/components/UserHistoryDrawer/UpdateUserUsernameMutation";
import { InvalidRequestError } from "coral-framework/lib/errors";
import { streamColorFromMeta } from "coral-framework/lib/form";
import {
  composeValidators,
  required,
  validateUsername,
} from "coral-framework/lib/validation";
import CLASSES from "coral-stream/classes";
import {
  Button,
  FormField,
  HelperText,
  HorizontalGutter,
  Icon,
  TextField,
} from "coral-ui/components/v2";
import Flex from "coral-ui/components/v2/Flex";
import { CallOut, ValidationMessage } from "coral-ui/components/v3";
import { FORM_ERROR, FormApi } from "final-form";
import { Field, Form } from "react-final-form";
import styles from "./MemberUsernameContainer.css";

interface Props {
  user: MemberUsernameContainer_user;
}

interface FormProps {
  username: string;
}

const MemberUsernameContainer: FunctionComponent<Props> = ({ user }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const updateUserUsername = useMutation(UpdateUserUsernameMutation);

  const closeSuccessMessage = useCallback(
    () => setShowSuccessMessage(false),
    []
  );

  // REMP - reset will set username to email (initial state)
  const onReset = useCallback(
    () =>
      updateUserUsername({
        userID: user.id,
        username: user.email ?? "",
        initialized: false,
      }),
    [updateUserUsername]
  );

  const onSubmit = useCallback(
    async (input: FormProps, form: FormApi) => {
      try {
        await updateUserUsername({
          userID: user.id,
          username: input.username,
          initialized: true,
        });
      } catch (err) {
        if (err instanceof InvalidRequestError) {
          return err.invalidArgs;
        }

        return {
          [FORM_ERROR]: err.message,
        };
      }

      setShowSuccessMessage(true);

      return;
    },
    [updateUserUsername]
  );

  return (
    <div>
      <HorizontalGutter spacing={2}>
        <h3 className={styles.title}>Username</h3>

        <Form onSubmit={onSubmit} initialValues={{ username: user.username === user.email ? "" : user.username }}>
          {({
            handleSubmit,
            submitError,
            pristine,
            invalid,
            dirtySinceLastSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <HorizontalGutter spacing={4}>
                <FormField>
                  <Field
                    name="username"
                    validate={composeValidators(required, validateUsername)}
                  >
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          fullWidth
                          id={input.name}
                          color={streamColorFromMeta(meta)}
                        />
                        <ValidationMessage
                          className={CLASSES.validationMessage}
                          meta={meta}
                        />
                      </>
                    )}
                  </Field>
                </FormField>
                {showSuccessMessage && (
                  <CallOut
                    color="success"
                    onClose={closeSuccessMessage}
                    className={cn(CLASSES.myUsername.form.successCallOut)}
                    icon={<Icon size="sm">check_circle</Icon>}
                    titleWeight="semiBold"
                    title="Username has been updated"
                    aria-live="polite"
                  />
                )}
                {submitError && (
                  <CallOut
                    color="error"
                    className={CLASSES.myUsername.form.errorMessage}
                    icon={<Icon size="sm">error</Icon>}
                    titleWeight="semiBold"
                    title={submitError}
                    role="alert"
                  />
                )}
                <HelperText>
                  When username is reset, user will be required to re-enter
                  his/her username.
                </HelperText>
                <div>
                  <Flex
                    className={styles.actions}
                    direction="row"
                    itemGutter="half"
                  >
                    <Button
                      className={cn(CLASSES.myUsername.form.saveButton)}
                      type="submit"
                      color="regular"
                      size="small"
                      disabled={pristine || (invalid && !dirtySinceLastSubmit)}
                    >
                      <span>Save</span>
                    </Button>
                    <Button color="alert" size="small" onClick={onReset}>
                      <span>Reset</span>
                    </Button>
                  </Flex>
                </div>
              </HorizontalGutter>
            </form>
          )}
        </Form>
      </HorizontalGutter>
    </div>
  );
};

const enhanced = withFragmentContainer<Props>({
  user: graphql`
    fragment MemberUsernameContainer_user on User {
      id
      email
      username
    }
  `,
})(MemberUsernameContainer);

export default enhanced;
