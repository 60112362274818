import React, { FunctionComponent } from "react";

export interface BioActionProps {
  action: "set" | "enabled" | "disabled";
}

const BioAction: FunctionComponent<BioActionProps> = ({ action }) => {
  if (action === "set") {
    return <span>Saved bio</span>;
  }
  if (action === "enabled") {
    return <span>Enabled bio</span>;
  }
  return <span>Disabled bio</span>;
};

export default BioAction;
