/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentsNumberLimitConfigContainer_formValues = {
    readonly commentsNumberLimit: {
        readonly weeklyLimit: number | null;
        readonly weeklyWarningThreshold: number | null;
    } | null;
    readonly " $refType": "CommentsNumberLimitConfigContainer_formValues";
};
export type CommentsNumberLimitConfigContainer_formValues$data = CommentsNumberLimitConfigContainer_formValues;
export type CommentsNumberLimitConfigContainer_formValues$key = {
    readonly " $data"?: CommentsNumberLimitConfigContainer_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentsNumberLimitConfigContainer_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentsNumberLimitConfigContainer_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "CommentsNumberLimitConfiguration",
            "kind": "LinkedField",
            "name": "commentsNumberLimit",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weeklyLimit",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weeklyWarningThreshold",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'd4815697ac54d7d16eeb7d0006283220';
export default node;
