/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type useAMP = {
    readonly amp: boolean;
    readonly " $refType": "useAMP";
};
export type useAMP$data = useAMP;
export type useAMP$key = {
    readonly " $data"?: useAMP$data;
    readonly " $fragmentRefs": FragmentRefs<"useAMP">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useAMP",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amp",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any);
(node as any).hash = 'd3093c957dc4b72ccf0532ab1855721e';
export default node;
