import React, { FunctionComponent } from "react";
import { Field } from "react-final-form";
import { graphql } from "react-relay";

import { withFragmentContainer } from "coral-framework/lib/relay";
import {
  FieldSet,
  FormField,
  FormFieldDescription, TextFieldAdornment,
} from "coral-ui/components/v2";

import ConfigBox from "../../ConfigBox";
import Header from "../../Header";
import TextFieldWithValidation from "../../TextFieldWithValidation";

import { formatEmpty, parseIntegerNullable } from "coral-framework/lib/form";
import styles from "./CommentsNumberLimitConfigContainer.css";

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment CommentsNumberLimitConfigContainer_formValues on Settings {
    commentsNumberLimit {
      weeklyLimit
      weeklyWarningThreshold
    }
  }
`;

interface Props {
  disabled: boolean;
}

const CommentsNumberLimitConfigContainer: FunctionComponent<Props> = ({ disabled }) => (
  <ConfigBox
    title={<Header container={<legend />}>Comments number limit</Header>}
    container={<FieldSet />}
  >
    <Field
      name="commentsNumberLimit.weeklyLimit"
      parse={parseIntegerNullable}
      format={formatEmpty}
    >
      {({ input, meta }) => (
        <FormField>
          <FormFieldDescription>
            Maximum number of comments per week per user. If no value is set,
            there is no limit.
            <br />
            Counter resets every Monday at 00:01.
          </FormFieldDescription>
          <TextFieldWithValidation
            {...input}
            classes={{
              input: styles.weeklyLimitTextField,
            }}
            id="commentsNumberLimit-weekly"
            type="number"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            adornment={<TextFieldAdornment>comments per week</TextFieldAdornment>}
            placeholder="e.g. 20"
            disabled={disabled}
            meta={meta}
          />
        </FormField>
      )}
    </Field>
    <Field
      name="commentsNumberLimit.weeklyWarningThreshold"
      parse={parseIntegerNullable}
      format={formatEmpty}
    >
      {({ input, meta }) => (
        <FormField>
          <FormFieldDescription>
            How many comments before the limit is reached a warning is shown. If
            no value is set, there is no warning.
          </FormFieldDescription>
          <TextFieldWithValidation
            {...input}
            classes={{
              input: styles.weeklyLimitTextField,
            }}
            id="commentsNumberLimit-weekly"
            type="number"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            adornment={<TextFieldAdornment>last comments</TextFieldAdornment>}
            placeholder="e.g. 3"
            disabled={disabled}
            meta={meta}
          />
        </FormField>
      )}
    </Field>
  </ConfigBox>
);

const enhanced = withFragmentContainer<Props>({})(
  CommentsNumberLimitConfigContainer
);

export default enhanced;
