/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RempAuthConfigContainer_formValues = {
    readonly integrations: {
        readonly remp: {
            readonly enabled: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
                readonly stream: boolean;
            };
        };
    };
    readonly " $refType": "RempAuthConfigContainer_formValues";
};
export type RempAuthConfigContainer_formValues$data = RempAuthConfigContainer_formValues;
export type RempAuthConfigContainer_formValues$key = {
    readonly " $data"?: RempAuthConfigContainer_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"RempAuthConfigContainer_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RempAuthConfigContainer_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "RempAuthIntegration",
                    "kind": "LinkedField",
                    "name": "remp",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthenticationTargetFilter",
                            "kind": "LinkedField",
                            "name": "targetFilter",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "admin",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "stream",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Auth",
    "abstractKey": null
} as any);
(node as any).hash = '550567e46bf8680394197451faace84c';
export default node;
