/* tslint:disable */
/* eslint-disable */
/* @relayHash 8769cb60b7aed7b2ae3aa756c87e5086 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "READER" | "STAFF" | "%future added value";
export type AuthCheckRouteQueryVariables = {};
export type AuthCheckRouteQueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly username: string | null;
        readonly email: string | null;
        readonly profiles: ReadonlyArray<{
            readonly __typename: string;
        }>;
        readonly role: USER_ROLE;
        readonly " $fragmentRefs": FragmentRefs<"RestrictedContainer_viewer">;
    } | null;
    readonly settings: {
        readonly auth: {
            readonly integrations: {
                readonly local: {
                    readonly enabled: boolean;
                    readonly targetFilter: {
                        readonly admin: boolean;
                        readonly stream: boolean;
                    };
                };
            };
        };
    };
};
export type AuthCheckRouteQuery = {
    readonly response: AuthCheckRouteQueryResponse;
    readonly variables: AuthCheckRouteQueryVariables;
};



/*
query AuthCheckRouteQuery {
  viewer {
    ...RestrictedContainer_viewer
    id
    username
    email
    profiles {
      __typename
    }
    role
  }
  settings {
    auth {
      integrations {
        local {
          enabled
          targetFilter {
            admin
            stream
          }
        }
      }
    }
    id
  }
}

fragment RestrictedContainer_viewer on User {
  username
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "profiles",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "concreteType": "Auth",
        "kind": "LinkedField",
        "name": "auth",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AuthIntegrations",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "LocalAuthIntegration",
                        "kind": "LinkedField",
                        "name": "local",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "enabled",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "AuthenticationTargetFilter",
                                "kind": "LinkedField",
                                "name": "targetFilter",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "admin",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "stream",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AuthCheckRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "RestrictedContainer_viewer"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AuthCheckRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v0 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        (v5 /*: any*/),
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "8769cb60b7aed7b2ae3aa756c87e5086",
            "metadata": {},
            "name": "AuthCheckRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '533a88be345dd9eaf24790ef439c1e79';
export default node;
