/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "READER" | "STAFF" | "%future added value";
export type UserRoleChangeContainer_user = {
    readonly id: string;
    readonly username: string | null;
    readonly role: USER_ROLE;
    readonly moderationScopes: {
        readonly scoped: boolean;
        readonly sites: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
        }> | null;
    } | null;
    readonly membershipScopes: {
        readonly scoped: boolean;
        readonly sites: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
        }> | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"SiteRoleActions_user">;
    readonly " $refType": "UserRoleChangeContainer_user";
};
export type UserRoleChangeContainer_user$data = UserRoleChangeContainer_user;
export type UserRoleChangeContainer_user$key = {
    readonly " $data"?: UserRoleChangeContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserRoleChangeContainer_user">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scoped",
            "storageKey": null
        } as any),
        ({
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "sites",
            "plural": true,
            "selections": [
                (v0 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "UserRoleChangeContainer_user",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "UserModerationScopes",
                "kind": "LinkedField",
                "name": "moderationScopes",
                "plural": false,
                "selections": (v1 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "UserMembershipScopes",
                "kind": "LinkedField",
                "name": "membershipScopes",
                "plural": false,
                "selections": (v1 /*: any*/),
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SiteRoleActions_user"
            }
        ],
        "type": "User",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '786bfee8673c6d5ec1d5084cfecd3c03';
export default node;
