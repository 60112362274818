/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MemberBioContainer_user = {
    readonly id: string;
    readonly bio: string | null;
    readonly status: {
        readonly bio: {
            readonly enabled: boolean;
            readonly history: ReadonlyArray<{
                readonly bio: string | null;
                readonly operation: string | null;
                readonly createdAt: string;
                readonly createdBy: {
                    readonly username: string | null;
                } | null;
            }>;
        };
    };
    readonly " $refType": "MemberBioContainer_user";
};
export type MemberBioContainer_user$data = MemberBioContainer_user;
export type MemberBioContainer_user$key = {
    readonly " $data"?: MemberBioContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"MemberBioContainer_user">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bio",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "MemberBioContainer_user",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            },
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "UserStatus",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BioStatus",
                        "kind": "LinkedField",
                        "name": "bio",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "enabled",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "BioStatusHistory",
                                "kind": "LinkedField",
                                "name": "history",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "operation",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "createdAt",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "kind": "LinkedField",
                                        "name": "createdBy",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "username",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "User",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '8c952f4096c47ff3c3429c423d0b9568';
export default node;
