import React, { FunctionComponent } from "react";
import { graphql } from "react-relay";

import { getURLWithCommentID } from "coral-framework/helpers";
import { withFragmentContainer } from "coral-framework/lib/relay";
import { Button, ButtonIcon, Icon } from "coral-ui/components/v2";

import { LinkDetailsContainer_comment } from "coral-admin/__generated__/LinkDetailsContainer_comment.graphql";
import { LinkDetailsContainer_settings } from "coral-admin/__generated__/LinkDetailsContainer_settings.graphql";

import styles from "./LinkDetailsContainer.css";

interface Props {
  comment: LinkDetailsContainer_comment;
  settings: LinkDetailsContainer_settings;
}

const LinkDetailsContainer: FunctionComponent<Props> = ({
  comment,
  settings,
}) => {
  return (
    <>
      <Button
        className={styles.buttonContainer}
        anchor={true}
        variant="regular"
        color="regular"
        href={getURLWithCommentID(comment.story.url, comment.id)}
        iconLeft
      >
        <ButtonIcon>link</ButtonIcon>
        Go to story comment
      </Button>
    </>
  );
};

const enhanced = withFragmentContainer<Props>({
  comment: graphql`
    fragment LinkDetailsContainer_comment on Comment {
      id
      story {
        id
        url
      }
    }
  `,
  settings: graphql`
    fragment LinkDetailsContainer_settings on Settings {
      organization {
        url
      }
    }
  `,
})(LinkDetailsContainer);

export default enhanced;
