/* tslint:disable */
/* eslint-disable */
/* @relayHash a8b8eaf8ad12bb57769cba2f732ce7c0 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserDrawerAccountHistoryQueryVariables = {
    userID: string;
};
export type UserDrawerAccountHistoryQueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"UserDrawerAccountHistory_user">;
    } | null;
};
export type UserDrawerAccountHistoryQuery = {
    readonly response: UserDrawerAccountHistoryQueryResponse;
    readonly variables: UserDrawerAccountHistoryQueryVariables;
};



/*
query UserDrawerAccountHistoryQuery(
  $userID: ID!
) {
  user(id: $userID) {
    ...UserDrawerAccountHistory_user
    id
  }
}

fragment UserDrawerAccountHistory_user on User {
  status {
    username {
      history {
        username
        createdAt
        createdBy {
          username
          id
        }
      }
    }
    warning {
      history {
        active
        createdBy {
          username
          id
        }
        acknowledgedAt
        createdAt
        message
      }
    }
    modMessage {
      history {
        active
        createdBy {
          username
          id
        }
        acknowledgedAt
        createdAt
        message
      }
    }
    ban {
      history {
        active
        createdBy {
          username
          id
        }
        createdAt
        sites {
          name
          id
        }
      }
      active
      sites {
        id
        name
      }
    }
    premod {
      history {
        active
        createdBy {
          username
          id
        }
        createdAt
      }
    }
    suspension {
      history {
        active
        from {
          start
          finish
        }
        createdBy {
          username
          id
        }
        modifiedAt
        modifiedBy {
          username
          id
        }
        createdAt
      }
    }
    bio {
      history {
        bio
        createdAt
        createdBy {
          username
          id
        }
        operation
      }
      enabled
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userID"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v5 = [
        (v2 /*: any*/),
        (v4 /*: any*/)
    ], v6 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "createdBy",
        "plural": false,
        "selections": (v5 /*: any*/),
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    } as any), v8 = [
        (v7 /*: any*/),
        (v6 /*: any*/),
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "acknowledgedAt",
            "storageKey": null
        } as any),
        (v3 /*: any*/),
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
        } as any)
    ], v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserDrawerAccountHistoryQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserDrawerAccountHistory_user"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserDrawerAccountHistoryQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UsernameStatus",
                                    "kind": "LinkedField",
                                    "name": "username",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UsernameHistory",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                (v6 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "WarningStatus",
                                    "kind": "LinkedField",
                                    "name": "warning",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "WarningStatusHistory",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": true,
                                            "selections": (v8 /*: any*/),
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ModMessageStatus",
                                    "kind": "LinkedField",
                                    "name": "modMessage",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ModMessageStatusHistory",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": true,
                                            "selections": (v8 /*: any*/),
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BanStatus",
                                    "kind": "LinkedField",
                                    "name": "ban",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "BanStatusHistory",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": true,
                                            "selections": [
                                                (v7 /*: any*/),
                                                (v6 /*: any*/),
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Site",
                                                    "kind": "LinkedField",
                                                    "name": "sites",
                                                    "plural": true,
                                                    "selections": [
                                                        (v9 /*: any*/),
                                                        (v4 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Site",
                                            "kind": "LinkedField",
                                            "name": "sites",
                                            "plural": true,
                                            "selections": [
                                                (v4 /*: any*/),
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PremodStatus",
                                    "kind": "LinkedField",
                                    "name": "premod",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PremodStatusHistory",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": true,
                                            "selections": [
                                                (v7 /*: any*/),
                                                (v6 /*: any*/),
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SuspensionStatus",
                                    "kind": "LinkedField",
                                    "name": "suspension",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SuspensionStatusHistory",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": true,
                                            "selections": [
                                                (v7 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "TimeRange",
                                                    "kind": "LinkedField",
                                                    "name": "from",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "start",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "finish",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v6 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "modifiedAt",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "modifiedBy",
                                                    "plural": false,
                                                    "selections": (v5 /*: any*/),
                                                    "storageKey": null
                                                },
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BioStatus",
                                    "kind": "LinkedField",
                                    "name": "bio",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "BioStatusHistory",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "bio",
                                                    "storageKey": null
                                                },
                                                (v3 /*: any*/),
                                                (v6 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "operation",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "enabled",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "a8b8eaf8ad12bb57769cba2f732ce7c0",
            "metadata": {},
            "name": "UserDrawerAccountHistoryQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'ce3871731c6080ad051a384610d29c60';
export default node;
