/* tslint:disable */
/* eslint-disable */
/* @relayHash d221289b5de79dc34828a21efbb58cca */

import { ConcreteRequest } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "READER" | "STAFF" | "%future added value";
export type ControlPanelRouteQueryVariables = {};
export type ControlPanelRouteQueryResponse = {
    readonly viewer: {
        readonly role: USER_ROLE;
    } | null;
};
export type ControlPanelRouteQuery = {
    readonly response: ControlPanelRouteQueryResponse;
    readonly variables: ControlPanelRouteQueryVariables;
};



/*
query ControlPanelRouteQuery {
  viewer {
    role
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ControlPanelRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ControlPanelRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "d221289b5de79dc34828a21efbb58cca",
            "metadata": {},
            "name": "ControlPanelRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '0fad64060224354c84f1d7e004a402dc';
export default node;
