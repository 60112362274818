/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MemberUsernameContainer_user = {
    readonly id: string;
    readonly email: string | null;
    readonly username: string | null;
    readonly " $refType": "MemberUsernameContainer_user";
};
export type MemberUsernameContainer_user$data = MemberUsernameContainer_user;
export type MemberUsernameContainer_user$key = {
    readonly " $data"?: MemberUsernameContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"MemberUsernameContainer_user">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MemberUsernameContainer_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '09d3adbb0245a0b0dbf503ef9f3df3d6';
export default node;
