/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserHistoryDrawerContainer_user = {
    readonly moderatorNotes: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly id: string;
    readonly username: string | null;
    readonly email: string | null;
    readonly createdAt: string;
    readonly " $fragmentRefs": FragmentRefs<"UserBadgesContainer_user" | "UserStatusChangeContainer_user" | "UserStatusDetailsContainer_user" | "RecentHistoryContainer_user" | "MemberBioContainer_user" | "MemberUsernameContainer_user">;
    readonly " $refType": "UserHistoryDrawerContainer_user";
};
export type UserHistoryDrawerContainer_user$data = UserHistoryDrawerContainer_user;
export type UserHistoryDrawerContainer_user$key = {
    readonly " $data"?: UserHistoryDrawerContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerContainer_user">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "UserHistoryDrawerContainer_user",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ModeratorNote",
                "kind": "LinkedField",
                "name": "moderatorNotes",
                "plural": true,
                "selections": [
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserBadgesContainer_user"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserStatusChangeContainer_user"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserStatusDetailsContainer_user"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RecentHistoryContainer_user"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MemberBioContainer_user"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MemberUsernameContainer_user"
            }
        ],
        "type": "User",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '7527edc73c9156132dd99f235e6fb943';
export default node;
